import { include } from 'named-urls';

export default {
    home: '/',
    dashboard: '/',

    myagenda: '/agenda/:client?/:date?',
    group: '/groupoverview/:date?',
    subgroup: '/subgroup/:subgroup?/:date?',
    searchfreeslot: '/searchfreeslot',
    history: '/history/:date?/:start?/:end?/:client?',
    conflicts: '/conflicts/:client?/:ids?',

    noaccess: '/401-no-access',
    forbidden: '/403-forbidden',
    notfound: '/404-not-found',
    error: '/500-error',

    auth: {
        login: '/login',
        forgotpassword: '/forgot-password',
        resetpasswordcode: '/reset-password/:code',
        resetpassword: '/reset-password',
        twofactor: '/two-factor'
    },

    agenda: include('/agenda', {
        client: 'client/:client?/:date?',
        group: 'group/:date?',
        subgroup: 'subgroup/:subgroup?/:date?',

        history: 'history/:date?/:start?/:end?/:client?',
        searchfreeslot: 'searchfreeslot',
        conflicts: 'conflicts/:client?/:ids?',

        print: include('print', {
            week: 'week/:date/:client?/:category?',
            day: 'day/:date/:client?/:category?',
            group: 'group/:date/:subgroup?',
        }),

        notes: include('notes', {
            overview: '',
            add: 'add/:client/:start/:allDay?',
            edit: 'edit/:client/:id'
        }),
    }),

    appointments: include('/appointments', {
        overview: '',
        new: 'new/:client/:start?/:end?',
        add: 'add/:client/:start?/:end?/:category?/:slot?/:subcategory?/:force?',
        edit: 'edit/:client/:id',
        remove: 'remove/:id',
        print: 'print/:id',
        copy: 'copy/:appointment/:date/:client?',
        move: 'move/:appointment/:date/:client?',
        moveClient: 'groupoverview/move/:client/:appointment/:date',
        search: 'search/:searchStr/:client?',
        statistics: include('statistics', {
            overview: ''
        }),
        open: include('open', {
            overview: '',
            add: 'add/:date/:time?/:category?',
            edit: 'edit/:id/:date?'
        }),
    }),

    homevisits: include('/homevisit', {
        overview: ':client?/:date?',
        add: 'add/:date?/:client?/:time?/:category?',
        edit: 'edit/:id/:applicableForIgnore?',
        printDay: 'print/:client?/:date?'
    }),

    dayappointments: include('/dayappointments', {
        overview: '',
        add: 'add/:client?/:date?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    patients: include('/patients', {
        overview: ':search?',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        appointments: 'appointments/:id/:repeated?',
        mail: 'mail/:id/:email?',
        sms: 'sms/:id',
        confirm: 'confirm',
        conflict: 'conflict'
    }),

    settings: include('/settings', {
        agenda: include('agenda', {
            overview: '',
            authentication: 'authentication',
            preferences: 'preferences',
            profile: 'profile'
        }),
        appointmentstatuses: include('appointmentstatuses', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        blocks: include('blocks', {
            overview: ':date?',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        categories: include('categories', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        exceptions: include('exceptions', {
            overview: ':type?',
            add: 'add/:date?/:slot?/:client?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        group: include('group', {
            overview: '',
            preferences: 'preferences',
            profile: 'profile',
            sort: 'sort',
            client: include('client', {
                overview: '',
                add: 'add',
                edit: 'edit/:id'
            })
        }),
        forms: include('forms', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',

            fields: include(':form/fields', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
            })
        }),
        holidays: include('holidays', {
            overview: ':type?',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            holidays: 'holidays'
        }),
        links: include('links', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        openinghour: include('openinghour/:type', {
            overview: '',
            add: 'add',
            edit: 'edit/:id'
        }),
        payments: include('payments', {
            links: include('links', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                payments: include('payments/:link', {
                    overview: ''
                })
            })
        }),
        schedules: include('schedules', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            compose: 'compose/:id/:wizard?',
            priority: 'priority/:id',
            homevisit: 'homevisit/:id',
            remove: 'remove/:id',
            periods: include('periods/:scheduleId', {
                overview: '',
                add: 'add/:wizard?',
                edit: 'edit/:id',
                remove: 'remove/:id'
            })
        }),
        subcategories: include('subcategories', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        subgroups: include('subgroups', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        tags: include('tags', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        workspaces: include('workspaces', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            schedules: include(':workspace/schedules', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                compose: ':schedule/compose',
                periods: include(':schedule/periods', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
        openappointmentmoment: include('open-appoinment-moment', {
            overview: '',
            settings: 'settings',
            schedules: include('schedules', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                compose: ':schedule/compose',
                periods: include(':schedule/periods', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
    }),

    communication: include('/communication', {
        mailbox: include('mailbox', {
            inbox: ':message?',
            compose: 'compose/:message?/:all?'
        }),
        tasks: include('tasks', {
            overview: '',
            new: 'new',
            add: 'add/:subcategory?',
            edit: 'edit/:id',
            remove: 'remove/:id',
            groups: include('groups', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id'
            })
        }),
        reminders: include('reminders', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        popups: include('popups', {
            overview: '',
            add: 'add/:client?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        mailing: include('mailing', {
            campaign: include('campaign', {
                overview: '',
                add: 'add',
                copy: 'copy/:id',
                edit: 'edit/:id',
                remove: 'remove/:id',
                schedule: include(':campaign', {
                    overview: 'schedule/:tab?',
                    statistics: 'statisctics/:schedule'
                })
            }),
            list: include('list', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                member: include(':list/members', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id'
                })
            }),
        }),
        mail: include('mail', {
            compose: '',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
        sms: include('sms', {
            compose: '',
            numbers: 'numbers',
            overview: 'overview',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
        flow: include('flow', {
            overview: '',
            notification: include('notification/:module/:moduleid', {
                overview: '',
                add: 'add/:id?',
                edit: 'edit/:id'
            }),
            template: include('template', {
                overview: '',
                add: 'add/:id?',
                edit: 'edit/:id'
            })
        })
    }),

    embed: {
        afo: 'a/fo/:path',
    },

    e: include('/e', {
        al: ':path',
        c: 'c/:path',
        g: 'g/:path',
        t: 't/:path',
        tc: 'tc/:path',
        tg: 'tg/:path',
        tgm: 'tgm/:path',
        gc: 'gc/:path',
        custom: 'custom/:path'
    })
}